import './App.css';
import AppHeader from './AppHeader' ;
import AppMain from './AppMain'   ;
import AppFooter from './AppFooter' ;
import { ThemeProvider } from '@mui/system';
import AppTheme from './AppTheme.js';
// #region fonts
// sans
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/300-italic.css';
import '@fontsource/lato/400-italic.css';
import '@fontsource/lato/700-italic.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/500-italic.css';
import '@fontsource/montserrat/600-italic.css';
import '@fontsource/montserrat/700-italic.css';
import '@fontsource/nunito/variable.css';
import '@fontsource/nunito/variable-italic.css';
// serif
import '@fontsource/lora/variable.css';
import '@fontsource/lora/variable-italic.css';
import '@fontsource/merriweather/300.css';
import '@fontsource/merriweather/400.css';
import '@fontsource/merriweather/700.css';
import '@fontsource/merriweather/300-italic.css';
import '@fontsource/merriweather/400-italic.css';
import '@fontsource/merriweather/700-italic.css';
// display
import '@fontsource/concert-one/400.css';
import '@fontsource/lobster-two/400.css';
import '@fontsource/lobster-two/700.css';
import '@fontsource/lobster-two/400-italic.css';
import '@fontsource/lobster-two/700-italic.css';
// #endregion

export default function App(){return(<ThemeProvider theme={AppTheme}><div className="App">
  <AppHeader />
  <AppMain />
  <AppFooter />
</div></ThemeProvider>);}
