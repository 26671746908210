import React, { useState, useEffect} from 'react';
// import TodoList  from './maincontent/TodoList';
// import { Button, TextField, FormGroup } from '@mui/material';
// import { v4 as uuidv4 } from 'uuid'; 
import spinner from './spinnerBig.svg';

const LOCAL_STORAGE_KEY = 'rnwtech.home';

export default function AppMain(){
  
/* | MAIN CONTENT IS A FLEXCOLUMN                                                        | 
 * | THE TODOLIST IS A FORMGROUP, BUT THE REST OF THE UI ARE STRUCTURED INSIDE FLEX ROWS | 
 * | INSIDE EACH ROW, COMPONENTS ARE WRAPPED IN A DIV, WHICH ACTS AS A FINAL SHELL FOR   | 
 * | CSS UTILITY CLASS STYLING SIMILAR TO BOOTSTRAP/TAILWIND ETC - THIS WORKS WELL WITH  | 
 * | ELEMENTS THAT CAN SET THEIR SIZE TO FILL THE SIZE OF THE PARENT DIV, ALLOWING MORE  | 
 * | DIRECT, CONVENTIONAL, AND UNIFIED CONTROL OVER LAYOUT LOGIC                         | 
 */                                                                                      
  
return(<main class="flexcc">
  <div class="flexrow">
  <div class="spinner-wrapper-Big"> <img src={spinner} className="spinner mp0" alt="spinner" /> </div>
  </div>
 </main>);}
