import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material/styles';

const AppTheme = createTheme(deepmerge({
  // LIGHT THEME 
  palette: {
    type: 'light',
    primary: {
      main: '#18c2e8',
      contrastText: '#1d1e1f',
    },
    secondary: {
      main: '#12b14c',
      contrastText: '#1d1f1e',
    },
    background: {
      default: '#f5faff',
      paper: '#f5faff',
    },
    text: {
      primary: 'rgba(29,30,31,0.85)',
      secondary: 'rgba(29,30,31,0.7)',
      disabled: 'rgba(29,30,31,0.5)',
      hint: 'rgba(29,30,31,0.35)',
    },
    success: {
      main: '#12b14c',
      contrastText: '#1d1f1e',
    },
    divider: '#1d1e1f',
  },
  // DARK THEME 
  palette: {
    type: 'dark',
    primary: {
      main: '#18c2e8',
      contrastText: '#1d1e1f',
    },
    secondary: {
      main: '#12b14c',
      contrastText: '#1d1f1e',
    },
    background: {
      default: '#282c34',
      paper: 'rgba(31,33,39,0.95)',
    },
    text: {
      primary: 'rgba(245,250,255,0.95)',
      secondary: 'rgba(245,250,255,0.8)',
      disabled: 'rgba(245,250,255,0.5)',
      hint: '#f5faff',
    },
    success: {
      main: '#12b14c',
      contrastText: '#1d1f1e',
    },
    divider: '#1d1e1f',
  },
  // TYPEOGRAPHY 
  typography: {
    fontFamily: 'Montserrat',
  },
}));

export default AppTheme;