import React from 'react';

export default function AppFooter(){return(<>
  <div class="footer-border">
    <div class="footer-border-inner"></div>
  </div>
  <footer class="flexrc shade justify-fs">
    <div>Links: 😃<a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" rel="noopener noreferrer" > Do you like free money? </a></div>
  </footer>
</>);}
