import React from 'react';
import spinner from './spinner.svg';

export default function AppHeader(){return(<>
  <header class="shade flexrc">
    <div class="spinner-wrapper"> <img src={spinner} className="spinner mp0" alt="spinner" /> </div>
    <div class="line flexrc">
      <p><a href="#top" class="font-display-lobs">RNW Tech</a></p>
    </div>
  </header>
  <div class="header-border">
    <div class="header-border-inner"></div>
  </div>
  <div id="top"/>
</>);}
